import React, { useState,useEffect } from 'react';

import { ComparatorRowBlock } from './_comparator_row_block';


export const ComparatorRowMobile = ({allData, data, item, titleActive = false, classNew="",_models}) => {

    const [filterItem, setFilterItem] = useState(item);
    const [filterIdeal, serFilterIdeal] = useState( getDataIdeal() );
    const [filterLink, setFilterLink] = useState( getLinkTechnical() );

    useEffect(()=>{
        changeItem(item.uid)
       
        serFilterIdeal(getDataIdeal());
        setFilterLink(getLinkTechnical());
        // console.log('filterItem', filterItem)
        // eslint-disable-next-line
    },[item])

    function changeItem(itemId) {
        const newFilterItem = allData.filter(item => item.uid === itemId );
        setFilterItem(newFilterItem[0])
    }

    function getDataIdeal(){
        return data.filter(ideal => ideal.uid === item.data.model.uid )[0].dataIdeal;
    }

    function getLinkTechnical(){
        return data.filter(ideal => ideal.uid === item.data.model.uid )[0].link_technical;
    }

    return (
        <div className={`comparator__row ${classNew}`}>

            <img className="comparator__row--image" src={filterItem.data.image.url} alt={filterItem.data.title.text}/>

            <ComparatorRowBlock
                blockClass="comparator__row--idealUses"
                title={"Usos ideales"}
                titleActive={titleActive}
                items={filterIdeal}/>

            <ComparatorRowBlock
                blockClass="comparator__row--capacity"
                title={filterItem.data.body[0].primary.block_title}
                titleActive={titleActive}
                items={filterItem.data.body[0].items}/>

            {
                filterItem.data.body.map((item, index) => {
                    if(item.__typename === "PrismicVersionBodyComparator") {
                        let _class = index === 1 ? "comparator__row--efficiency" : "comparator__row--datasheet";
                        return <ComparatorRowBlock
                            blockClass={_class}
                            title={item.primary.title1}
                            titleActive={titleActive}
                            items={item.items}
                            key={index}/>
                    }else return null
                })
            }

            { filterLink!==null ?
                <a href={filterLink} className="comparator_link_datasheet" target="_blank" rel="noopener noreferrer">
                    <p className="">Ficha Técnica</p>
                </a> : null
            }
        </div>
    )
}