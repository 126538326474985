import React, { useEffect, useState, useRef } from "react";

import "../../styles/pages/comparator/_comparator.scss";

import DropdownLine from '../../components/_comparator/dropdown_line';
import IconArrow from '../../components/svg/commons/arrow_head';
import CssClassManager from '../../components/_helpers/_css_class_manager';

const ComparatorMenuMobile =(_props)=>{
    
    const versions = _props._versions;
    const models = _props._models;
    const [arrayModels,setArrayModels] = useState([]);
    
    const [leftModel, setLeftModel] = useState(_props._models[0]);
    const [rightModel, setRightModel] = useState(_props._models[1]);
    
    const [defaultTextModelLeft, setDefaultTextModelLeft] = useState(_props._models[0].name);
    const [defaultTextModelRight, setDefaultTextModelRight] = useState(_props._models[1].name);

    const [defaultTextVersionLeft, setDefaultTextVersionLeft] = useState('');
    const [defaultTextVersionRight, setDefaultTextVersionRight] = useState('');
    
    const [leftVersion, setLeftVersion] = useState();
    const [rightVersion, setRightVersion] = useState();
    const [versionByModel,setVersionsByModel]= useState([]);
    const [arrayVersionsLeft,setArrayVersionsLeft]= useState([]);
    const [arrayVersionsRight,setArrayVersionsRight]= useState([]);
    const [menuLeftActive,setMenuLeftActive]= useState(false);
    const [menuRightActive,setMenuRightActive]= useState(false);
    
    const menu_mobile = useRef(null);

    useEffect(()=>{
        let fixed = false
        let cssclassManager = new CssClassManager();
        setLeftModel(_props._models[0])
        setRightModel(_props._models[1])

        window.addEventListener('scroll', ()=>{
            if(menu_mobile.current===null)return;
            let lastScrollY = window.scrollY || document.body.scrollTop || document.documentElement.scrollTop;
            lastScrollY = lastScrollY *-1;
            

            if(!fixed &&lastScrollY<=-163){
                fixed=true;
                
                cssclassManager.add_class(menu_mobile.current,'comparator__menu__container--fixed')
            }
            else{
                if(fixed && lastScrollY>-163)
                {
                    cssclassManager.remove_class(menu_mobile.current,'comparator__menu__container--fixed')
                    fixed= false;
                }
            }
        })
    },[_props]);
    
    useEffect(() => {
        
        var models_array= [];
        
        _props._models.forEach((edge)=>{            
            let model_uid = edge.uid;
            
            models_array[model_uid]=edge
            
        });
        setArrayModels(models_array);        

        var version_by_models= [];
        
        _props._versions.forEach((edge)=>{
            
            let model_uid = edge.data.model.uid;
            if(version_by_models[model_uid]===undefined)
            {
                version_by_models[model_uid]=[];
            }
            version_by_models[model_uid].push(edge)
            
        });
          
        let version_left = version_by_models[leftModel.uid]
        let version_right = version_by_models[rightModel.uid]
        
        setVersionsByModel(version_by_models);

        setLeftVersion(version_left[0]);
        setRightVersion(version_right[0]);

        setArrayVersionsLeft(version_left)
        setArrayVersionsRight(version_right)

        setDefaultTextVersionLeft(version_left[0].data.title.text)
        setDefaultTextVersionRight(version_right[0].data.title.text)
         // console.log(leftModel.uid)
         // eslint-disable-next-line
    },[])
    
    function changeModel(_uid,_from_left){
        
        if(_from_left){
            
            setDefaultTextModelLeft(arrayModels[_uid].name);
            setArrayVersionsLeft(versionByModel[_uid])
            setDefaultTextVersionLeft('Selecciona una versión');
        }else{
            setDefaultTextModelRight(arrayModels[_uid].name);
            setArrayVersionsRight(versionByModel[_uid])
            setDefaultTextVersionRight('Selecciona una versión');
        }
    }
    function changeVersion(_uid,_from_left){
        
        let selected_version = null;
        let found = false;
        let count = 0;
        
        while(!found && count < versions.length){
            let version = versions[count];
            if(version.uid===_uid){
                found=true;
                selected_version= version
            }
            else{
                count++;
            }
        }
        _props._on_change_item(count,_from_left);
        
        if(_from_left)
        {
            setLeftVersion(selected_version);
            setDefaultTextVersionLeft(selected_version.data.title.text)
            setMenuLeftActive(false);
        }
        else{
            setRightVersion(selected_version);
            setDefaultTextVersionRight(selected_version.data.title.text)
            setMenuRightActive(false);
        }
    }
    function openMenu(_from_left){        
        if(_from_left){

            setMenuLeftActive(!menuLeftActive);
            setMenuRightActive(false);
        }
        else{
            setMenuRightActive(!menuRightActive);
            setMenuLeftActive(false);
        }
    }
    function is_opened(_opened){
        // console.log('opened',_opened);
        let cssclassManager = new CssClassManager();
        if(_opened){
            cssclassManager.add_class(menu_mobile.current,'comparator__menu__container--opened')
        }else{
            cssclassManager.remove_class(menu_mobile.current,'comparator__menu__container--opened')
        }
        
   }

    let class_left_active = menuLeftActive ? 'comparator__menu__item--active' : '';
    let class_right_active = menuRightActive ? 'comparator__menu__item--active' : '';

    let class_left_inactive = menuRightActive ? 'comparator__menu__item--inactive' : '';
    let class_right_inactive =  menuLeftActive ? 'comparator__menu__item--inactive' : '';
    return (
        <div className="comparator__menu__container_stage " >

        
            <div className="comparator__menu__container" ref={menu_mobile}>
                <div className="comparator__menu__header">
                    <div className={`comparator__menu__left comparator__menu__item ${class_left_active} ${class_left_inactive} `} onClick={()=>{openMenu(true)}}>
                        <p>{leftVersion && leftVersion.data.title.text}</p> 
                        <IconArrow />
                    </div>
                    <div className={`comparator__menu__right comparator__menu__item ${class_right_active} ${class_right_inactive} `} onClick={()=>{openMenu(false)}}>
                        <p>{rightVersion && rightVersion.data.title.text}</p>
                        <IconArrow />
                    </div>
                    <div className="comparator__menu__spacer"></div>
                </div>
                <div className="comparator__menu__body">
                    {
                        menuLeftActive ? 
                        <div className="comparator__menu__body__left">
                            <div className="comparator__menu__model">
                                <DropdownLine items={models} default_item={defaultTextModelLeft} handleItemClick={(_uid)=>{changeModel(_uid,true)}}/>
                            </div>
                            <div className="comparator__menu__version">
                                <DropdownLine items={arrayVersionsLeft} default_item={defaultTextVersionLeft} handleItemClick={(_uid)=>{changeVersion(_uid,true)}} placeholder='Selecciona Versión' handleOpen={is_opened}/> 
                            </div>
                        </div> : null
                    }
                    {
                        menuRightActive ? 
                        <div className="comparator__menu__body__right">
                            <div className="comparator__menu__model">
                                <DropdownLine items={models} default_item={defaultTextModelRight} handleItemClick={(_uid)=>{changeModel(_uid,false)}}/>
                            </div>
                            <div className="comparator__menu__version">
                                <DropdownLine items={arrayVersionsRight} default_item={defaultTextVersionRight} handleItemClick={(_uid)=>{changeVersion(_uid,false)}} placeholder='Selecciona Versión' handleOpen={is_opened}/> 
                            </div>
                        </div> : null
                    }
                    
                </div>
            </div>
        </div>
    );
};

export default ComparatorMenuMobile
