import React, { Fragment } from "react"

import Layout from "../components/layout"
import { ComparatorContainer } from "../content/comparator/_comparator_container"
import HeaderTitle from "../components/_titles/_header_title"
import SvgFloodlight from "../components/svg/comparator/floodlight"
import { useCurrenDevice } from "../hooks/useCurrentDevice"

import "../styles/pages/comparator/_comparator.scss"

const PageComparator = () => {
  const [device] = useCurrenDevice()

  return (
    <Layout header_visible={true}>
      <div className="comparator">
        <HeaderTitle title="Comparador" subtitle="Conoce las características que hacen único a cada calentador." isMainTitle={true} _classTitle="comparator__title" />
        <ComparatorContainer />
      </div>
      <div className="comparator__footer">
        <SvgFloodlight />
        <div className="comparator__footer--description">
          {device === "desktop" ? (
            <Fragment>
              <div>
                <p>(1) Un servicio equivale a una regadera de 5 l/min.</p>
                <p>(2) Medio servicio equivale a un lavabo de 3 l/min.</p>
                <p>(3) Una tina equivale a 150L.</p>
              </div>
              <div>
                <p>(4) Se recomienda instalar el calentador lo más cerca de la regadera.</p>
                <p>(5) Modelos disponibles en Gas LP o gas natural, las medidas de altura y diámetro son nominales.</p>
                <p>(6) Válvula de alivio 150 (PSI) para modelos COXDP-15 y COXDP-20.</p>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <p>(1) Un servicio equivale a una regadera de 5 l/min.</p>
              <p>(2) Medio servicio equivale a un lavabo de 3 l/min.</p>
              <p>(3) Una tina equivale a 150L.</p>
              <p>(4) Se recomienda instalar el calentador lo más cerca de la regadera.</p>
              <p>(5) Modelos disponibles en Gas LP o gas natural, las medidas de altura y diámetro son nominales.</p>
              <p>(6) Válvula de alivio 150 (PSI) para modelos COXDP-15 y COXDP-20.</p>
            </Fragment>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default PageComparator
