import React, { useEffect, useState, Fragment,useRef } from "react";

import "../../styles/pages/comparator/_comparator.scss";
import { ComparatorRow } from "./_comparator_row";
import { ComparatorRowMobile } from "./_comparator_row_mobile";
import { useCurrenDevice } from "../../hooks/useCurrentDevice";
import { usePrismVersions } from '../../hooks/use_prism-versions';
import { usePrismModels } from '../../hooks/use_prism-models';
import { usePrismCategories } from '../../hooks/use_prims_categories';
import ComparatorMenuMobile from '../comparator/_comparator_menu_mobile';

import CssClassManager from '../../components/_helpers/_css_class_manager';

export const ComparatorContainer = () => {
    const [device] = useCurrenDevice();
    const data = usePrismVersions().nodes;
    const [versions, setVersions] = useState(null)
    const [leftVersion, setLeftVersion] = useState(null)
    const [rightVersion, setRightVersion] = useState(null)
    const models = usePrismModels().nodes;
    const categories = usePrismCategories().nodes;
    

    let fixed = false    
    let cssclassManager = new CssClassManager();
    const menu = useRef(null);

    useEffect(() => {
       
        let orderModels = [];
        categories.map(category => {
          return  models.filter(model => model.data.category.uid === category.uid)
                .map(model => {
                    const versions_array = data.filter(version => version.data.model.document[0].data.model_name.text === model.data.model_name.text);

                    versions_array.sort(function(a, b) {
                        return a.data.sort_index - b.data.sort_index;
                    });
                    let dataIdeal;
                    let link_technical;
                    model.data.body.map(element =>{
                        if(element.__typename === "PrismicModelBodyUsosIdeales"){
                          return  dataIdeal = element.items;
                        }
                        if(element.__typename === "PrismicModelBodyArchivos"){
                           return link_technical = element.primary.technical.url
                        }else return null
                    });
                return orderModels = [...orderModels, {
                        name: model.data.model_name.text,
                        uid: model.uid,
                        versions: versions_array,
                        dataIdeal: dataIdeal,
                        link_technical: link_technical
                    }];
                })
        })

        setVersions(orderModels);
        setLeftVersion(orderModels[0].versions[0]);
        setRightVersion(orderModels[1].versions[0]);
        
    },[categories, models, data])

    function add_listener(){
        window.addEventListener('scroll', ()=>{
            if(menu.current===null)return;
            let lastScrollY = window.scrollY || document.body.scrollTop || document.documentElement.scrollTop;
            lastScrollY = lastScrollY *-1;
            

            if(!fixed &&lastScrollY<=-148){
                fixed=true;
                
                cssclassManager.add_class(menu.current,'comparator__menu__container--fixed')
            }
            else{
                if(fixed && lastScrollY>-148)
                {
                    cssclassManager.remove_class(menu.current,'comparator__menu__container--fixed')
                    fixed= false;
                }
            }
        })
    }
    function onChangeItem(_id,_from_left){

        if(_from_left){
            setLeftVersion(data[_id])
        }
        else{
            setRightVersion(data[_id])
        }
    }

    function getContent(){
        const winWidth =window.innerWidth;
        if(winWidth<768){
            return(
                <>
                    <ComparatorMenuMobile _versions={data} _models={versions} _on_change_item={onChangeItem} />
                    <div className="comparator__row_container">
                        <ComparatorRowMobile allData={data} _models={models} data={versions} item={leftVersion} titleActive={true} classNew="comparator__row--1" />
                        <ComparatorRowMobile allData={data} _models={models} data={versions} item={rightVersion} classNew="comparator__row--2"/>
                    </div>
                </>
            )
        }
        else{
            add_listener();
            return (
                <>
                    <ComparatorRow allData={data} _models={models} data={versions} item={versions[0].versions[0]} titleActive={true} classNew="comparator__row--1" />
                    <ComparatorRow allData={data} _models={models} data={versions} item={versions[1].versions[0]} classNew="comparator__row--2"/>
                    {device === "desktop" ? <ComparatorRow allData={data} _models={models} data={versions} item={versions[2].versions[0]} classNew="comparator__row--3"/> : null}
                </>
            )
        }
    }

    return (
        <div className="comparator__container" ref={menu}>
            {
                versions &&
                <Fragment>
                    {getContent()}
                </Fragment>
            }
        </div>
    );
};


