import React, { useState, useRef, useEffect } from 'react';

import useOutsideClick from '../../hooks/useOutsideClick'
import IconArrow from '../svg/commons/arrow_head';
import "../../styles/_components/_comparator/_dropdown_line.scss";

const DropdownLine = ({items, default_item = "name item", handleItemClick, _className="", type=1,placeholder='',handleOpen=null}) => {
    const [isActive, setActive] = useState(false);
    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, setActive);

    //useEffect for add and remove class for z-index=1
    useEffect(()=>{
        
        if(isActive){
            wrapperRef.current.classList.add("o-dropdown__active");
        }else{
            wrapperRef.current.classList.remove("o-dropdown__active");
        }
        if(handleOpen!=null){
            handleOpen(isActive);
        }
        
    },[isActive, handleOpen])

    function handleClick(id) {
        handleItemClick(id);
        setActive(false);
    }

    function getItems() {
        return (
            <div className="o-dropdownline__list">
                <div className="o-dropdownline__list--wrapper" onClick={() => setActive(!isActive)}>
                    <ul>
                        {
                            items.map(item => {
                                return(
                                    <li key={item.uid} onClick={() => handleClick(item.uid)}>
                                        { item.data && item.data.title && <span>{item.data.title.text}</span> }
                                        { item.data && item.data.model_name && <span>{item.data.model_name.text}</span> }
                                        { item.name && <span>{item.name}</span> }
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        )
    }

    return (
        <div className="o-dropdownline" ref={wrapperRef}>
            <div className={`o-dropdownline__button ${_className}`} onClick={() => setActive(!isActive)} >
                <p>{default_item}</p>
                <IconArrow />
            </div>
            {
                isActive && getItems()
            }
        </div>
    );
};

export default DropdownLine;
