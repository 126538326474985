import React, { useEffect, useState } from "react";

import "../../styles/pages/comparator/_comparator.scss";

import DropdownLine from '../../components/_comparator/dropdown_line';
import IconArrow from '../../components/svg/commons/arrow_head';

const ComparatorMenuTablet =(_props)=>{
    
    const versions = _props._versions;
    const models = _props._models;
   // const current_version = _props._current_version;
    const handleItemClick  = _props.handleItemClick;
    const [arrayModels,setArrayModels] = useState([]);
    // console.log('current_version',current_version);
   //  const [model, setModel] = useState(_props._models[0]);
    const [defaultTextModel, setDefaultTextModel] = useState('');
    const [defaultTextVersion, setDefaultTextVersion] = useState(_props._current_version.data.title.text);
    const [version, setVersion] = useState(_props._current_version);
    const [versionByModel,setVersionsByModel]= useState([]);
    const [arrayVersions,setArrayVersions]= useState([]);
    const [menuActive,setMenuActive]= useState(false);

    useEffect(() => {

        var models_array= [];
        // console.log('_props',_props);
        //to get an array indexed on uid
        _props._models.forEach((edge)=>{
            let model_uid = edge.uid;
            models_array[model_uid]=edge
        });
        setArrayModels(models_array);

        var version_by_models= [];
        // console.log('_props',_props);

        _props._versions.forEach((edge)=>{
            let model_uid = edge.data.model.uid;
            if(version_by_models[model_uid]===undefined)
            {
                version_by_models[model_uid]=[];
            }
            version_by_models[model_uid].push(edge)
        });

        setVersionsByModel(version_by_models);

        let model_default = models_array[version.data.model.uid]
       // setModel(model_default);
        setDefaultTextModel(model_default.data.model_name.text)
        setArrayVersions(version_by_models[model_default.uid])
    }, [_props, version])

    function changeModel(_uid){
        setDefaultTextModel(arrayModels[_uid].data.model_name.text);
        setArrayVersions(versionByModel[_uid])
        setDefaultTextVersion('Selecciona una versión');
    }
    function changeVersion(_uid){
        let selected_version = null;
        let found = false;
        let count = 0;
        while(!found && count < versions.length){
            let version = versions[count];
            if(version.uid===_uid){
                found=true;
                selected_version= version
            }
            else{
                count++;
            }
        }
        setVersion(selected_version);
        setDefaultTextVersion(selected_version.data.title.text)
        setMenuActive(false);
        handleItemClick(_uid, selected_version);
    }
    function openMenu(){
        setMenuActive(!menuActive);
    }

    let class_active = menuActive ? 'comparator__menu__item--active' : ''
    return (
        <div className="comparator__menu__container">
                <div className="comparator__menu__header">
                    <div className={`comparator__menu comparator__menu__item ${class_active}`} onClick={()=>{openMenu()}}>
                        <p>{version && version.data.title.text}</p>
                        <IconArrow />
                    </div>
                </div>
                <div className="comparator__menu__body">
                    {
                        menuActive ?
                        <div className="comparator__menu__body__single">
                            <div className="comparator__menu__model">
                                <DropdownLine items={models} default_item={defaultTextModel} handleItemClick={(_uid)=>{changeModel(_uid)}}/>
                            </div>
                            <div className="comparator__menu__version">
                                <DropdownLine items={arrayVersions} default_item={defaultTextVersion} handleItemClick={(_uid)=>{changeVersion(_uid)}} placeholder='Selecciona Versión'/> 
                            </div>
                        </div> : null
                    }
                </div>
            </div>
    );
};

export default ComparatorMenuTablet
