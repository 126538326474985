import React, { useState } from 'react';

import { ComparatorRowBlock } from './_comparator_row_block';
import ComparatorMenuTablet from '../comparator/_comparator_menu_tablet';

export const ComparatorRow = ({allData, data, item, titleActive = false, classNew="",_models}) => {

    const [filterItem, setFilterItem] = useState(item);
    const [filterIdeal, serFilterIdeal] = useState( getDataIdeal(item) );
    const [filterLink, setFilterLink] = useState( getLinkTechnical(item) );

    function changeItem(itemId, dataItem) {
        const newFilterItem = allData.filter(item => item.uid === itemId );
        setFilterItem(newFilterItem[0])
        serFilterIdeal(getDataIdeal(dataItem));
        setFilterLink(getLinkTechnical(dataItem));
    }

    function getDataIdeal(dataItem){
        return data.filter(ideal => ideal.uid === dataItem.data.model.uid )[0].dataIdeal;
    }

    function getLinkTechnical(dataItem){
        return data.filter(ideal => ideal.uid === dataItem.data.model.uid )[0].link_technical;
    }

    return (
        <div className={`comparator__row ${classNew}`}>
            <div className="comparator__row__menu">
                <ComparatorMenuTablet _versions={allData} _models={_models} _current_version={item} handleItemClick={changeItem}/>
            </div>

            <img className="comparator__row--image" src={filterItem.data.image.url} alt={filterItem.data.title.text}/>

            <ComparatorRowBlock
                blockClass="comparator__row--idealUses"
                title={"Usos ideales"}
                titleActive={titleActive}
                items={filterIdeal}/>

            <ComparatorRowBlock
                blockClass="comparator__row--capacity"
                title={filterItem.data.body[0].primary.block_title}
                titleActive={titleActive}
                items={filterItem.data.body[0].items}/>

            {
                filterItem.data.body.map((item, index) => {
                    if(item.__typename === "PrismicVersionBodyComparator") {
                        let _class = index === 1 ? "comparator__row--efficiency" : "comparator__row--datasheet";
                        return <ComparatorRowBlock
                            blockClass={_class}
                            title={item.primary.title1}
                            titleActive={titleActive}
                            items={item.items}
                            key={index}/>
                    }else return null
                })
            }
            { filterLink!==null ?
                <a href={filterLink} className="comparator_link_datasheet" target="_blank" rel="noopener noreferrer">
                    <p className="">Ficha Técnica</p>
                </a> : null
            }
        </div>
    )
}