import React from 'react'
import headerStyles from '../../styles/_components/_titles/_header_title.module.scss'
import CalorexLogo from '../svg/commons/calorex_logo'
import { isNullOrUndefined } from 'util'

function HeaderTitle({title, subtitle, isMainTitle = false, _classTitle=""}) {

    function getClassTitle(){
        if(!isNullOrUndefined(_classTitle)){
            return `${headerStyles[_classTitle]}`;
        }else{
            return null;
        }
    }

    return (
        <div className={headerStyles.header__container}>
            <div className={`o-header_icon_container ${headerStyles.header__icon}`}>
                <CalorexLogo />
            </div>
            {isMainTitle ?  <h1 className={`${headerStyles.header__title} ${_classTitle} ${getClassTitle()}`}>{title}</h1> :  <h2 className={`${headerStyles.header__title} ${getClassTitle()}`}>{title}</h2>}
            <p className={headerStyles.header__subtitle}>{subtitle}</p>
        </div>
    )
}

export default HeaderTitle
